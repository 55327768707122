import * as React from 'react';

import * as styles from './Auth.css';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { CheckboxField } from '../../../components/CheckboxField';

interface IAuthProps {
  email: string;
  password: string;
  persistent: boolean;
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPersistentChange: (checked: boolean) => void;
  onLogin: (event: React.ChangeEvent<HTMLFormElement>) => void;
  errorPermissions: boolean;
  errorRequest: boolean;
  errorValidationMessage: string;
}

export const Auth: React.FC<IAuthProps> = ({
  email,
  password,
  persistent,
  onEmailChange,
  onPasswordChange,
  onPersistentChange,
  onLogin,
  errorPermissions,
  errorRequest,
  errorValidationMessage,
}) => {
  return (
    <div>
      <div className="mt-5 mb-4 border-bottom pb-2">
        <h1>Вход на сайт</h1>
      </div>
      <Form onSubmit={onLogin}>
        <Row className="mb-3">
          <Col md={2} className="text-end mt-auto mb-auto">
            <Form.Label>
              Email <span className="text-danger">*</span>
            </Form.Label>
          </Col>
          <Col md={4}>
            <Form.Control
              type="text"
              className="form-control"
              id="email"
              value={email}
              onChange={onEmailChange}
              name="email"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={2} className="text-end mt-auto mb-auto">
            <Form.Label>
              Пароль <span className="text-danger">*</span>
            </Form.Label>
          </Col>
          <Col md={4}>
            <Form.Control
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={onPasswordChange}
              name="password"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={{ span: 4, offset: 2 }}>
            <CheckboxField value={persistent} onChange={onPersistentChange} label="запомнить меня" />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={{ span: 4, offset: 2 }}>
            <Button type="submit" className={styles['submit']}>
              Войти
            </Button>
          </Col>
        </Row>
      </Form>
      {errorPermissions && <div className="text-danger">Недостаточно прав для просмотр данного раздела</div>}
      {errorValidationMessage && <div className="text-danger">{errorValidationMessage}</div>}
      {errorRequest && <div className="text-danger">Произошла ошибка во время запроса, попробуйте еще.</div>}
    </div>
  );
};

import { logOn } from '@cian/auth';
import { useEventCallback } from '@cian/react-utils';
import { getUserRoleList } from '../../../http';
import { useApplicationContext } from 'shared/common/utils/applicationContext';
import { logOut } from 'shared/common/utils/logout';
import { hasAccessByRoles, ALL_ROLES } from 'shared/common/utils/permissions';
import { Auth } from '../../components/Auth';
import { INITIAL_ERRORS } from './constants';
import { useState, ChangeEvent, useCallback, useEffect } from 'react';

export const AuthContainer = () => {
  const { config, httpApi, logger } = useApplicationContext();

  const authUrl = config.getStrict<string>('authUrl');

  const [form, updateForm] = useState({ email: '', password: '', persistent: false });
  const [errors, setErrors] = useState(INITIAL_ERRORS);

  const updateEmail = useEventCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    updateForm(prevState => ({ ...prevState, email: value }));
  });

  const updatePassword = useEventCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    updateForm(prevState => ({ ...prevState, password: value }));
  });

  const updatePersistent = useEventCallback((checked: boolean) => {
    updateForm(prevState => ({ ...prevState, persistent: checked }));
  });

  const updateErrors = useEventCallback((errors: Partial<typeof INITIAL_ERRORS>) => {
    setErrors(prevState => ({ ...prevState, ...errors }));
  });

  const handleLogin = useCallback(
    async (e: ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();

      updateErrors(INITIAL_ERRORS);

      const { email, password, persistent } = form;

      try {
        await logOn(email.trim(), password.trim(), persistent, authUrl);
      } catch (response) {
        const errors = response.errors
          ? { errorValidationMessage: response.message || response.errors[0].message }
          : { errorRequest: true };

        updateErrors(errors);

        return;
      }

      try {
        const { roles } = await getUserRoleList(httpApi, logger, { email });

        if (hasAccessByRoles(roles, ALL_ROLES)) {
          window.location.reload();
        } else {
          updateErrors({ errorPermissions: true });
        }
      } catch (err) {
        updateErrors({ errorRequest: true });
      }
    },
    [authUrl, form, httpApi, logger, updateErrors],
  );

  useEffect(() => {
    logOut(config, false);
  }, [config]);

  return (
    <Auth
      {...form}
      {...errors}
      onEmailChange={updateEmail}
      onPasswordChange={updatePassword}
      onPersistentChange={updatePersistent}
      onLogin={handleLogin}
    />
  );
};
